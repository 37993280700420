import React, { Component } from "react";
import "../css/TheWord.scss";

import TTLoader from "./TTLoader";
/**
 * トランスレーション展 Tim 作品
 *
 * w1080 × h 1980 の画面サイズで動作確認してください
 * @returns {*}
 * @constructor
 */
export default class TheWord extends Component {
  constructor(props) {
    super(props);
    new TTLoader();
    this._timeoutId = 0;
    this.state = {
      step: props.debugMode ? 3 : 0,
    };

    this._steps = [
      {
        css: `step-start`,
        waiting: `1000`,
      },
      {
        css: `step-word`,
        waiting: `7000`,
      },
      {
        css: `step-meaning`,
        waiting: `20000`,
      },
      {
        css: `step-all`,
        waiting: `20000`,
      },

      {
        css: `step-finish`,
      },
    ];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevWord = prevProps.word;
    const currentWord = this.props.word;
    if (prevWord !== currentWord) {
      this._timeoutId = setTimeout(() => {
        this._goToNext();
      }, 1000);
    }
  }

  componentDidMount() {
    this._timeoutId = setTimeout(() => {
      this._goToNext();
    }, 1000);
  }

  _goToNext() {
    // デバッグ時は状態を `all` に固定
    if (this.props.debugMode) {
      this.setState((state) => ({
        step: 3,
      }));
      return;
    }

    clearTimeout(this._timeoutId);

    let nextStep = this.state.step + 1;
    if (nextStep >= this._steps.length) {
      nextStep = 0;
    }

    this.setState((state) => ({
      step: nextStep,
    }));

    console.log(
      `アニメーションのステップ ${nextStep + 1} / ${this._steps.length}`
    );

    if (this._steps[nextStep].waiting) {
      this._timeoutId = setTimeout(() => {
        this._goToNext();
      }, this._steps[nextStep].waiting);
      console.log(`待ち時間は ${this._steps[nextStep].waiting} ms`);
    }
  }

  /**
   *
   * @param mode_ 0:yomi, 1:word
   * @param str1
   * @param str2
   * @private
   */
  _calcFontSize(mode_, str1, str2) {
    let keisu = 1.4;
    if (mode_ === 0) {
      let numChars = str1.length * 1.2 + str2.length;
      let size = 100 / numChars;
      return Math.min(size, 4) + "vw";
    } else {
      let numChars = str1.length;
      let size = (100 / numChars) * keisu;

      if (str1 === `السَّلَامُ عَلَيْكُمْ`) {
        size = 15;
      } else if (str1 === "Ցավդ տանեմ") {
        size = 12;
      }

      return Math.min(size, 16) + "vw";
    }
  }

  _nl2br(str) {
    if (!str) {
      return str;
    }
    str = str.replace(/\r\n/g, "<br />");
    str = str.replace(/(\n|\r)/g, "<br />");
    return str;
  }

  render() {
    // const data = this._data[this.state.dataIndex];
    const data = this.props;
    const appStep = this._steps[this.state.step];

    const inlineStyleYomi = {
      fontSize: this._calcFontSize(0, data.phonetic, data.phoneticEn),
      color: data.cssColorType,
    };
    const inlineStyleWord = {
      fontSize: this._calcFontSize(1, data.word),
      color: data.cssColorType,
    };

    console.log(
      `data.word ${data.word} / length: ${data.word.length} / fontsize ${inlineStyleWord.fontSize}`
    );

    return (
      <div
        className={appStep.css + ` app `}
        style={{
          color: data.cssColorType,
        }}
      >
        <div className={`word`}>
          <div className={`word__category`}>
            <span lang={`ja`}>{data.categoryTitleJa}</span>{" "}
            <span lang={`en`}>{data.categoryTitle}</span>
          </div>
          <div className={`word__theWordBox`}>
            <div className={`word__theWord`} style={inlineStyleWord}>
              {data.word}
            </div>
            <div className={`word__lang`}>
              <div
                lang={`ja`}
                dangerouslySetInnerHTML={{ __html: this._nl2br(data.langName) }}
              />
              <div
                lang={`en`}
                dangerouslySetInnerHTML={{
                  __html: this._nl2br(data.langNameEn),
                }}
              />
            </div>
          </div>
        </div>

        <div className={`meaning`}>
          <div className={`meaning__inner`}>
            <div className={`meaning__ja`}>
              <h2 style={inlineStyleYomi}>{data.phonetic}</h2>
              <p className={`meaning__body`}>
                <i>{data.type}</i>
                {data.meaning}
              </p>
            </div>

            <div className={`meaning__en`}>
              <h2 style={inlineStyleYomi}>{data.phoneticEn}</h2>
              <p className={`meaning__body`}>
                <i>{data.typeEn}</i>
                {data.meaningEn}
              </p>
            </div>
          </div>
        </div>

        <div
          className={`lang`}
          style={{
            background: data.cssColorType,
          }}
        >
          <div className={`lang__contents`}>
            <figure>
              <img src={data.mapImgSrc} alt="" />

              {data.natives ? (
                <figcaption>
                  <span lang={`ja`}>
                    {data.area}に<span>{data.natives}</span>人の話者
                  </span>
                  <br />
                  <span lang={`en`}>
                    <span>{data.natives}</span> native speakers in {data.areaEn}
                  </span>
                </figcaption>
              ) : (
                <figcaption>
                  <span lang={`ja`}>話者数は不明</span>
                  <br />
                  <span lang={`en`}>Unknown number of speakers.</span>
                </figcaption>
              )}
            </figure>
          </div>
        </div>
      </div>
    );
  }
}
