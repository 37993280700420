import React, { Component } from "react";

class ClockNavi extends Component {
  constructor() {
    super();
    this.state = {
      currentTime: new Date(),
    };
  }

  componentDidMount() {
    this._doUpdateTime();
  }

  _doUpdateTime() {
    this.setState((state) => ({
      currentTime: new Date(),
    }));

    setTimeout(() => {
      this._doUpdateTime();
    }, 30 / 1000);
  }

  render() {
    const year = this.state.currentTime.getFullYear();
    const month = this.state.currentTime.getMonth() + 1;
    const date = this.state.currentTime.getDate();

    let hour = this.state.currentTime.getHours();
    hour = ("00" + hour).slice(-2);

    let min = this.state.currentTime.getMinutes();
    min = ("00" + min).slice(-2);

    let sec = this.state.currentTime.getSeconds();
    sec = ("00" + sec).slice(-2);

    let ms = this.state.currentTime.getMilliseconds();
    ms = ("000" + ms).slice(-3);

    const strDate = `${year}/${month}/${date}`;
    const strTime = `${hour}:${min}:${sec}:${ms}`;

    return (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          zIndex: 10000,
          display: "inline-block",
          padding: "0.5em",
          backgroundColor: "rgba(0,0,0,0.8)",
          lineHeight: 1,
          color: "white",
          fontSize: "14px",
          fontFamily:"Monaco, monospace"
        }}
      >
        <span>
          <span>Ver.{this.props.version}</span> <span>{strDate}</span>{" "}
          <span>{strTime}</span>
        </span>
      </div>
    );
  }
}

export default ClockNavi;
