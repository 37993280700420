// データソース
// https://docs.google.com/spreadsheets/d/1kpqfN1o7kf7QYf-EL64z0GIPmDmSNipZcXwVLq9rJ54/edit#gid=0
// このファイルを CSV で書き出し、JSON に変換したものを利用する

import axios from "axios"

export default class TTLoader {
  constructor(machineId) {
    console.log(`This Machine ID: ${machineId}`);

    if (machineId) {
      this.machineId = parseInt(machineId);
    }

    const url = "/runtime/data.json";

    this._source = axios.CancelToken.source();
    axios({
      method: "get",
      url: url,
      cancelToken: this._source.token,
    })
      .then((res) => {
        this._wordsData = res.data;
        this.init();
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return;
        }
      });
  }

  init() {
    this.languageList = this._wordsData.languages;
    this.categoryList = this._wordsData.categories;
    this.wordList = this._wordsData.words;

    this.onComplete && this.onComplete();
  }

  /**
   * 単語リストを取得
   * @returns {*}
   */
  getWordList() {
    return this.wordList;
  }

  /**
   * 言語情報を取得する
   * @param languageSlug
   * @returns {undefined|*}
   */
  getLanguage(targetLang) {
    for (let i = 0; i < this.languageList.length; i += 1) {
      if (this.languageList[i].language === targetLang) {
        return this.languageList[i];
      }
    }
    return undefined;
  }

  /**
   * カテゴリ情報を取得する
   * @param categorySlug
   * @returns {undefined|*}
   */
  getCategory(targetCat) {
    for (let i = 0; i < this.categoryList.length; i += 1) {
      if (this.categoryList[i].category === targetCat) {
        return this.categoryList[i];
      }
    }
    return undefined;
  }

  /**
   * 必要なデータをマージする
   */
  getMergedData() {
    let result = [];
    for (let i = 0; i < this.wordList.length; i += 1) {
      let entry = {};
      Object.assign(entry, this.wordList[i]); // 単語データをコピー

      console.log(`this machine id `, this.machineId);

      if (
        this.machineId === Number(entry.machine) ||
        this.machineId === undefined
      ) {
        let srcLanguageObject = this.getLanguage(entry.language);
        let srcCategoryObject = this.getCategory(entry.category);

        Object.assign(entry, srcLanguageObject); // 言語データをコピー
        Object.assign(entry, srcCategoryObject); // カテゴリデータをコピー

        if (!entry.filePath) {
          entry.filePath = "./default-map.png";
        }

        result.push(entry);
      }
    }

    console.log(`==============`);
    console.log(`データの取得が完了`);
    console.log(result);
    console.log(`==============`);
    return result;
  }
}

/*
*
*
* ID: "2"
area: ""
area_ja: ""
category: "フレンドシップ"
categoryEn: "Friendship"
categorySlug: "friendship"
filePath: "./images/bakweri.png"
language: "Bakweri"
languageSlug: "bakweri"
language_ja: "バクウェリ語"
machine: "1"
meaing_ja: "眠っている子供の笑顔。"
meaning: "The smile of a sleeping child"
natives: "20,000"
notes: "."
phonetic: "Kwadi"
phonetic_ja: "うぉんば"
theme: "Contentment"
theme_ja: "満足"
type: "noun"
type_ja: "名詞"
word: "Womba"
重複チェック: "1"
* */
