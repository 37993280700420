import React, { Component } from "react";
import MyStats from "./components/MyStats";
import ClockNavi from "./components/ClockNavi";
import { Helmet } from "react-helmet";
import TTLoader from "./components/TTLoader";
import TTWebSocket from "./components/TTWebSocket";
import TheWord from "./components/TheWord";
import "./css/TheWord.scss";
import packageJson from "../package.json";
import url from "url";
import Doc from "./components/utils/Doc";
import WebFontPrefetch from "./components/WebFontPrefetch";

/**
 * トランスレーション展 Tim 作品
 *
 * w1080 × h 1980 の画面サイズで動作確認してください
 * @returns {*}
 * @constructor
 */
class App extends Component {
  /**
   *
   * @private
   */
  constructor() {
    super();
    this.state = {
      dataIndex: -1,
      socketData: null,
      configLoaded: false,
      debugMode: false,
      webfontPrefetch: "0_STAY",
    };

    this._standaloneTimeoutDuration = 55000;

    this._standaloneTimeout = null;
    this._onReceiveSocket = this._onReceiveSocket.bind(this);
    this._onConnectError = this._onConnectError.bind(this);
    this._onConnectTimeOut = this._onConnectTimeOut.bind(this);
    this._onKeyDownCheck = this._onKeyDownCheck.bind(this);
  }

  /**
   *
   * @private
   */
  componentDidMount() {
    console.log(
      `Component did mount and start loading config => ${process.env.REACT_APP_RUNTIME_CONFIG_PATH}`
    );

    // Config を読み込み
    fetch(process.env.REACT_APP_RUNTIME_CONFIG_PATH)
      .then((res) => res.json())
      .then(
        (result) => {
          this._init(result);
        },
        (error) => {
          console.log("Config の読み込みでエラーが発生しました", error);
        }
      );
  }

  _init(result) {
    if (this.state.configLoaded) {
      return;
    }

    if (process.env.REACT_APP_DEBUG === "1") {
      this._stats = new MyStats();
    }

    const urlQuery = url.parse(window.location.href, true).query;
    const machineId = urlQuery.machineid ? urlQuery.machineid : null;

    this._loader = new TTLoader(machineId);
    this._loader.onComplete = ()=>{
      this._start(result);
    }
  }


  _start(result){
    this._data = this._loader.getMergedData();

    console.log(`Initialized: ${this._data.length} records`);

    this.setState((state) => ({
      configLoaded: true,
      webfontPrefetch: "1_LOADING",
    }));
    console.log(`Prefetch Font Data`);

    setTimeout(() => {
      console.log(`Finished Prefetch Font Data`);
      this.setState((state) => ({
        webfontPrefetch: "2_LOADED",
      }));
      this._doStart(result);
    }, 7000);

  }

  _doStart(result) {
    this._websocket = new TTWebSocket(result.SOCKET_SERVER);
    this._websocket.onFired(this._onReceiveSocket);
    this._websocket.onConnectError(this._onConnectError);
    this._websocket.onTimeout(this._onConnectTimeOut);
  }

  /**
   *
   * @private
   */
  _onReceiveSocket(data) {
    this._standaloneTimeout && clearTimeout(this._standaloneTimeout);

    this.setState((state) => ({
      socketData: data,
      dataIndex: data.que,
      errorMessage: "",
    }));
  }

  /**
   *
   * @private
   */
  _onConnectError() {
    this.setState((state) => ({
      errorCode: "Error code: 1",
      errorMessage:
        "サーバーに接続できません。Socket サーバーが起動しているか、IPアドレスの指定が正しいか確認してください。",
    }));
  }

  /**
   *
   * @private
   */
  _onConnectTimeOut() {
    this.setState((state) => ({
      errorCode: "Standalone Mode",
      errorMessage: "タイムアウトしたのでスタンドアローンモードで再生します",
    }));

    this._websocket.onConnectError(null);
    setTimeout(() => {
      this._startAsStandalone();
    }, 1500);
  }

  /**
   *
   * @private
   */
  _startAsStandalone() {
    this.setState((state) => ({
      dataIndex: 0,
    }));
    this._continueStandalone();
  }

  /**
   *
   * @private
   */
  _continueStandalone() {
    clearTimeout(this._standaloneTimeout);

    this._standaloneTimeout = setTimeout(() => {
      this._goToNext();
      this._continueStandalone();
    }, this._standaloneTimeoutDuration);
  }

  _goToNext() {
    let next = this.state.dataIndex + 1;
    if (next >= this._data.length) {
      next = 0;
    }
    this.setState((state) => ({
      dataIndex: next,
    }));
  }

  _goToPrev() {
    let next = this.state.dataIndex - 1;
    if (next < 0) {
      next = this._data.length - 1;
    }
    this.setState((state) => ({
      dataIndex: next,
    }));
  }
  /**
   * デバッグ用:
   * @param e
   * @private
   */
  _onKeyDownCheck(e) {
    // Space キーでデバッグモードに
    if (e.keyCode === 32) {
      this._toggleDebugMode();
    } else if (e.keyCode === 39) {
      this._goToNext();
    } else if (e.keyCode === 37) {
      this._goToPrev();
    }
  }

  /**
   * デバッグモード切り替え
   * @private
   */
  _toggleDebugMode() {
    if (this.state.debugMode) {
      this.setState((state) => ({
        debugMode: false,
      }));
      this._doResetDebugMode();
    } else {
      this.setState((state) => ({
        debugMode: true,
      }));
      this._doDebugMode();
    }
  }

  _doDebugMode() {
    if (!this._standaloneTimeout) return;
    this._standaloneTimeoutDuration = 100000000000000;
    console.log("===========================");
    console.log("デバッグモードに入りました");
    console.log("左・右キーでページが移動できます");
    console.log("===========================");
  }

  _doResetDebugMode() {
    if (!this._standaloneTimeout) return;
    this._standaloneTimeoutDuration = 550000;
    this._continueStandalone();
    console.log("===========================");
    console.log("通常モードに戻りました");
    console.log("===========================");
  }

  /**
   *
   * @private
   */
  render() {
    if (!this.state.configLoaded) {
      console.log(`Waiting for the config loading...`);
      return <></>;
    }

    const data = this._data[this.state.dataIndex];

    let socketDate = "";
    if (this.state.socketData) {
      socketDate = "Websocket: " + new Date(this.state.socketData.value) + " |";
    }

    if (this.state.errorMessage) {
      socketDate = `${this.state.errorCode} |`;
    }

    data && console.log(data);



    return (
      <div onKeyDown={this._onKeyDownCheck} tabIndex="0">
        <Helmet>
          <title>2121PL Client</title>
          <meta http-equiv="refresh" content="64800; URL=" />
        </Helmet>

        {process.env.REACT_APP_DEBUG === "1" && (
          <ClockNavi version={`${packageJson.version} | ${socketDate}`} />
        )}

        {this.state.dataIndex >= 0 ? (
          <TheWord
            cssBackgroundType={data.background}
            cssColorType={data.color}
            word={data.word}
            phonetic={data.phonetic_ja}
            phoneticEn={data.phonetic}
            type={data.type_ja}
            typeEn={data.type}
            langName={
              data.languageAlt_ja ? data.languageAlt_ja : data.language_ja
            }
            meaning={data.meaning_ja}
            langNameEn={data.languageAlt ? data.languageAlt : data.language}
            meaningEn={data.meaning}
            natives={data.natives}
            area={data.area_ja}
            areaEn={data.area}
            mapImgSrc={`${process.env.REACT_APP_RUNTIME_DIR}${data.filePath}`}
            debugMode={this.state.debugMode}
            categoryTitle={data.category_title}
            categoryTitleJa={data.category_title_ja}
          />
        ) : (
          <div
            style={{
              padding: "5vw",
            }}
          >
            {this.state.errorMessage ? (
              <Doc>
                <h2>{this.state.errorCode}</h2>
                <p>{this.state.errorMessage}</p>
              </Doc>
            ) : (
              <Doc>
                <h2>Now Initializing...</h2>
                <p>
                  Waiting for the first web socket. If it does not start in one
                  minute, check to see if 2121PL Server is starting.
                </p>
                <p>
                  最初の Web ソケットを待っています。もし 1
                  分たっても起動しない場合は 17 PL-SyncServer
                  が起動しているかを確認してください。
                </p>
              </Doc>
            )}
          </div>
        )}

        {this.state.webfontPrefetch === "1_LOADING" && (
          <div>
            <div
              style={{
                padding: "5vw",
              }}
            >
              <Doc>
                <h3>Loading Font Data</h3>
              </Doc>
              <WebFontPrefetch data={this._data} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default App;
