export default class TTWebSocket {

  static get TIMEOUT (){
    return 2500
  }

  constructor(server_ = "http://localhost") {
    const io = require("socket.io-client");
    const ioSocket = io.connect(server_); // チャットサーバーに接続

    console.log(`Connecting to ${server_}`)

    this._connectError = null;
    this._fired = null;
    this._timeout = null;

    ioSocket.on("connect", () => {});

    ioSocket.on("disconnect", () => {});

    ioSocket.on("connect_error", () => {
      this._connectError && this._connectError();

      if (!this._timeout) {
        this._timeout = setTimeout(() => {
          this._onTimeout && this._onTimeout();
          console.log(`Cannot connect to server. Timeout duration is ${TTWebSocket.TIMEOUT}ms`);
        }, TTWebSocket.TIMEOUT);
      }
    });

    // サーバーからクライアントへの送り返し
    ioSocket.on("server_to_client", (data) => {
      this._fired(data);
    });
  }

  onFired(callback) {
    this._fired = callback;
  }

  onConnectError(callback) {
    this._connectError = callback;
  }

  onTimeout(callback) {
    this._onTimeout = callback;
  }
}
