import React, { Component } from "react";
import Doc from "./utils/Doc";

class WebFontPrefetch extends Component {
  render() {
    let allChars = "";
    for (let i = 0; i < this.props.data.length; i += 1) {
      let theItem = this.props.data[i];
      let fields = [
        `area`,
        `area_ja`,
        `category_title`,
        `category_title_ja`,
        `language`,
        `language_ja`,
        `meaning`,
        `meaning_ja`,
        `phonetic`,
        `phonetic_ja`,
        `natives`,
        `theme`,
        `theme_ja`,
        `type`,
        `type_ja`,
        `word`,
      ];

      for (let j = 0; j < fields.length; j += 1) {
        allChars += theItem[fields[j]];
      }
    }

    allChars = allChars.split("");
    allChars = allChars.filter(function (x, i, self) {
      return self.indexOf(x) === i;
    });

    allChars = allChars.join(",");
    allChars = allChars.replace(/,/g, "");

    return (
      <div style={{  }}>{allChars}</div>
    );
  }
}

export default WebFontPrefetch;
