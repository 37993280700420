import React, { Component } from "react";
import css from "./Doc.module.scss";

/**
 * トランスレーション展 Tim 作品
 *
 * w1080 × h 1980 の画面サイズで動作確認してください
 * @returns {*}
 * @constructor
 */
export default class Doc extends Component {
  /**
   *
   * @private
   */
  constructor() {
    super();
  }

  /**
   *
   * @private
   */

  render() {
    return <div className={css.base}>{this.props.children}</div>;
  }
}
